import React, {useEffect, useRef, useState} from 'react';
import {IonHeader, IonPage} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from "react-router-dom";

import {StyledIonContent, StyledIonTitle, StyledIonToolbar} from '@components/content/content.style';
import TravelAllowanceSubMenuModal from "@modals/menu/travelAllowanceSubMenu.component";
import RegulationTravelPreviewModal from "@app/subMenuPage/modals/regulationTravelPreviewModal.component";
import {acceptRegulation, getRegulationInfo} from "@services/travelAllowance/regulation.service";

const SubMenuPage: React.FC = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const header = useRef<HTMLIonHeaderElement>(null);

    const [topEdge, updateTopEdge] = useState<number | undefined>(undefined);
    const [title, setTitle] = useState<string>('');
    const [subMenuModal, setSubMenuModal] = useState<JSX.Element | null>(null);

    const [showRegulationTravelPreviewModal, updateShowRegulationTravelPreviewModal] = useState<boolean>(false);
    const [regulationData, updateRegulationData] = useState<any>(false);
    const history = useHistory();

    const getRegulationInfoData = async () => {
        const response = await getRegulationInfo();
        if (response.valid == false) {
            updateShowRegulationTravelPreviewModal(true);
            updateRegulationData(response.data);
        } else {
            setTitle(getTitle());
            setSubMenuModal(renderSubMenuModal());
        }
    };

    useEffect(() => {
        if ('/main/travel-allowance' == location.pathname) {
            getRegulationInfoData();
        }
    }, [location.pathname, topEdge]);

    useEffect(() => {
        updateHeight();
    });


    const accept = async () => {
        if (regulationData) {
            await acceptRegulation(regulationData.id)
                .then(response => {
                    console.log(response)
                    updateShowRegulationTravelPreviewModal(false);
                    setTitle(getTitle());
                    setSubMenuModal(renderSubMenuModal());
                });
        }
    }

    const updateHeight = () => {
        if (header.current?.clientHeight === 0) {
            setTimeout(updateHeight);
        } else {
            updateTopEdge(header.current?.clientHeight);
        }
    };

    const getTitle = () => {
        switch (location.pathname) {
            case '/main/travel-allowance':
                return t('travelAllowance.subMenu.title');
            default:
                return '';
        }
    }

    const renderSubMenuModal = () => {
        switch (location.pathname) {
            case '/main/travel-allowance':
                return <TravelAllowanceSubMenuModal topEdge={topEdge}/>;
            default:
                return null;
        }
    };

    return (
        <div>
            <IonPage>
                <StyledIonContent>
                    <IonHeader ref={header} className="ion-no-border">
                        <StyledIonToolbar>
                            <StyledIonTitle>{title}</StyledIonTitle>
                        </StyledIonToolbar>
                    </IonHeader>
                    {subMenuModal}
                </StyledIonContent>
            </IonPage>
            <RegulationTravelPreviewModal
                isOpen={showRegulationTravelPreviewModal}
                onConfirm={() => {
                    accept();
                }}
                onClose={() => {
                    updateShowRegulationTravelPreviewModal(false);
                    history.goBack();
                }}
                regulation={regulationData}
            > </RegulationTravelPreviewModal>
        </div>
    );
};

export default SubMenuPage;