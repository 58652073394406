import React from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../components/modal/modal.component';
import {StyledFooter} from "../../components/pane/pane.style";
import Form from "../../components/form";
import {Links} from "../../app/links";
import {useHistory} from "react-router-dom";
import {StyledContainer} from "../../app/mainPage/tabs/settings/modals/appVersionModal.style";
import {disableSwipeToClose} from "../../utils/tools/modals";

type WorkerAlreadyConnectedModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const WorkerAlreadyConnectedModal: React.FC<WorkerAlreadyConnectedModalProps> = (props: WorkerAlreadyConnectedModalProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    const openStore = async () => {
        history.replace(Links.main + Links.profile);
    };

    return (
        <Modal
            isOpen={props.isOpen}
            maxHeight={'50%'}
            canDismiss={disableSwipeToClose}
            disableClose={true}
            initialBreakpoint={0.99}
            breakpoints={[0, 0.99]}
            onDidDismiss={() => props.onClose()}>
            <StyledContainer>
                <p className="center">{t('becameTeamMember.waitForAccept.content')}</p>
                <StyledFooter className="no-shadow">
                    <Form.Button type="submit" onClick={() => openStore()}>{t('becameTeamMember.waitForAccept.continue')}</Form.Button>
                </StyledFooter>
            </StyledContainer>

        </Modal>
    );
};

export default WorkerAlreadyConnectedModal;
