import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Modal from '../../../components/modal/modal.component';
import {StyledModalContent} from '../../../components/modal/modal.style';
import {getRegulationTravelPdf} from "@services/travelAllowance/regulation.service";
import {blobToBase64} from "../../../services/file.service";
import {FilesystemDirectory, Filesystem, Directory} from "@capacitor/filesystem";
import {StyledFooter} from "../../../components/pane/pane.style";
import {AndroidSettings, IOSSettings, NativeSettings} from "capacitor-native-settings";
import {IonAlert, IonCol, IonGrid, IonItem, IonRow} from "@ionic/react";
import {FileOpener} from "@capacitor-community/file-opener";
import {Device} from "@capacitor/device";
import Form from "@components/form";
import {useForm} from "react-hook-form";
import CloseImage from "@assets/images/e-remove.svg";
import LinkLabel from "@components/form/input/label.component";
import {StyledIonLabel} from "@components/form/input/label.style";
import {StyledIonCheckbox} from "@components/form/input/checkbox.style";
import {StyledButton, StyledLightBlueButton, StyledWiteButton} from "@components/button/button.style";

type TerminatePreviewModalProps = {
    isOpen: boolean;
    regulation?: any,
    onClose: () => void;
    onConfirm: () => void;
};

const RegulationTravelPreviewModal: React.FC<TerminatePreviewModalProps> = (props: TerminatePreviewModalProps) => {

    const {t} = useTranslation();

    const [show, updateShow] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);
    const [regulationAccepted, updateRegulationAccepted] = useState<boolean>(false);

    const handleClose = () => {
        if (show) {
            updateShow(false);
            props.onClose();
        }
    };

    const {handleSubmit} = useForm({
        mode: 'all'
    });

    const handleSign = () => {
        props.onConfirm();
    };

    useEffect(() => {
        updateRegulationAccepted(false);
    }, [props.isOpen]);

    useEffect(() => {
        handleOpen();
    });

    const handleOpen = () => {
        updateShow(props.isOpen);
    };

    const openRegulationTravelPdf = async () => {
        if (props.regulation) {
            const info = await Device.getInfo();
            let deniedPermissions = false;
            if (info.operatingSystem === 'android' && parseFloat(info.osVersion) < 13) {
                let hasPermissions = await Filesystem.checkPermissions();
                deniedPermissions = hasPermissions.publicStorage === 'denied';
            }

            if (deniedPermissions) {
                setShowPermissionAlert(true);
            } else {
                let fileData = await getRegulationTravelPdf(props.regulation.id)
                    .then(response => {
                        return response.data;
                    })

                let base64 = null;
                if (fileData) {
                    await blobToBase64(fileData).then(value => base64 = value);
                    if (base64) {
                        try {
                            const result = await Filesystem.writeFile({
                                path: 'ExactPeople/travelRegulation.pdf',
                                data: base64,
                                directory: Directory.Library,
                                recursive: true
                            })

                            FileOpener.open({
                                'filePath': result.uri,
                                'contentType': 'application/pdf'
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        }
    }

    return (
        <Modal
            isOpen={show}
            canDismiss={true}
            onDidDismiss={() => handleClose()}>
            <Form.Container className="modalForm" onSubmit={handleSubmit(handleSign)}>
                <StyledModalContent>
                    <img src={CloseImage} className="close" onClick={() => handleClose()}/>
                    <h1 className="title">{props.regulation?.name}</h1>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{__html: props.regulation?.content || '4'}}/>
                        <LinkLabel urlString={t("")} url={'#'}>
                            <IonItem lines="none">
                                <StyledIonLabel
                                    className="ion-text-wrap">{t('regulationTravel.approvals.checkboxes.regulationAccepted')}</StyledIonLabel>
                                <StyledIonCheckbox name="regulationAccepted" slot="start" onIonChange={(e) => {
                                    updateRegulationAccepted(e.detail.checked);
                                }}/>
                            </IonItem>
                        </LinkLabel>
                    </div>
                    <StyledFooter className="modal footer no-shadow">
                        <IonGrid>
                            <IonRow>
                                <IonCol
                                    size="12">
                                    <Form.Button type="submit"
                                                 disabled={!regulationAccepted}>{t("regulationTravel.approvals.acceptButton")}</Form.Button>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size="6">
                                    <StyledWiteButton type="button" className="red"
                                                      onClick={() => handleClose()}>{t("regulationTravel.content.rejectButton")}</StyledWiteButton>
                                </IonCol>
                                <IonCol
                                    size="6">
                                    <StyledWiteButton type="button" onClick={() => openRegulationTravelPdf()}>
                                        {t('regulationTravel.content.openPdf')}
                                    </StyledWiteButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </StyledFooter>
                </StyledModalContent>
            </Form.Container>
            <IonAlert
                isOpen={showPermissionAlert}
                onDidDismiss={() => setShowPermissionAlert(false)}
                header={t('common.file_permissions.alert')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowPermissionAlert(false);
                        },
                    },
                    {
                        text: t('common.file_permissions.goToAppSettings'),
                        handler: async () => {
                            setShowPermissionAlert(false);
                            NativeSettings.open({
                                optionAndroid: AndroidSettings.ApplicationDetails,
                                optionIOS: IOSSettings.App
                            })
                        }
                    }
                ]}
            />
        </Modal>
    );
};

export default RegulationTravelPreviewModal;