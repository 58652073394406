import React, {useEffect, useState} from 'react';
import {IonCol, IonIcon, IonItem, IonLoading, IonRow, IonSelect, IonSelectOption, IonToast} from '@ionic/react';
import {useTranslation} from 'react-i18next';
import moment from "moment";
import {Controller, useFormContext} from "react-hook-form";

import {Car} from "@models/travelAllowance/car";
import {BackRideForm, Passenger, RideFormData} from "@models/travelAllowance/ride";
import {RideCost} from "@models/travelAllowance/rideCost";

import Accordion from "@components/accordion/accordion";
import DateInput from '@components/form/input/date.component';

import CalendarIcon from '@assets/images/travelAllowance/calendar.svg';
import CityAutocomplete from "@components/google/cityAutocomplete/cityAutocomplete.component";
import PassengersFormField from "@app/travelAllowance/ride/components/passengersFormField.component";

import {useRideFormContext} from "@context/rideForm.context";

import {useEvent} from "@hooks/useEvent";

import {StyledIonLabel} from "@components/form/input/label.style";
import {StyledIonCheckbox} from "@components/form/input/checkbox.style";
import {StyledInput, StyledInputGroup, StyledInputUnit} from "@components/form/input/input.style";
import {EventType} from "@enums/eventType";
import {calculateRideCost} from "@services/travelAllowance/rideCost.service";

type RideFormFieldsProps = {};

const BackRideFormFields: React.FC<RideFormFieldsProps> = ({}: RideFormFieldsProps) => {
    const {t} = useTranslation();
    const {register, control, getValues, setValue} = useFormContext<RideFormData>();
    const {listen} = useEvent();

    const rideFormContext = useRideFormContext();

    const [selectedPassengers, setSelectedPassengers] = useState<Passenger[] | undefined>(undefined);
    const [dateStart, setDateStart] = useState<string>('');
    const [backRideDateStart, setBackRideDateStart] = useState<string>(moment().format('YYYY-MM-DD'));
    const [checkedBackRide, setCheckedBackRide] = useState<boolean>(false);
    const [backRide, setBackRide] = useState<Omit<BackRideForm, "dateStart">>();
    const [rideCost, setRideCost] = useState<RideCost>();

    const [showToast, setShowToast] = useState<boolean>(false);
    const [toast, setToast] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        listen(EventType['REFRESH.BACK_RIDE_FORM_FIELDS'], handleBackRideSet);
    }, []);

    useEffect(() => {
        if (checkedBackRide && backRide?.route.startingPlaceCountry && backRide.route.destinationPlaceCountry && backRide.route.distance && backRide.car && backRideDateStart && typeof selectedPassengers !== "undefined") {
            setShowLoader(true);

            const handleError = (error: any, errorMessageKey: string) => {
                const errorMessage = error.response.data.detail;

                if (errorMessage === 'Company vehicle rate not found') {
                    setToast(t("travelAllowance.rideCost.companyVehicleRateNotFound"));
                    setValue('backRide.car', undefined);
                    setRideCost(undefined);
                } else {
                    setToast(t(errorMessageKey));
                }
                setShowToast(true);

                console.error(error);
            };

            fetchRideCost(backRide.route.startingPlaceCountry, backRide.route.destinationPlaceCountry, backRide.route.distance, backRide.car, backRideDateStart, selectedPassengers)
                .catch((error) => handleError(error, "travelAllowance.rideCost.calculateError"))
                .finally(() => setShowLoader(false))
        }
    }, [checkedBackRide, backRide, selectedPassengers, backRideDateStart]);

    useEffect(() => {
        const { ride } = getValues();

        if (!ride) return;

        setBackRidePassengers();
        setBackRideDateStart(moment(ride.dateStart).format('YYYY-MM-DD'));
    }, [checkedBackRide]);

    useEffect(() => {
        if (moment(backRideDateStart).isAfter(moment(dateStart).add(1, 'days'))) {
            setBackRideDateStart(dateStart);
        }
    }, [dateStart])

    const fetchRideCost = async (startingPlaceCountry: string, destinationPlaceCountry: string, distance: number, car: number, dateStart: string, passengers?: Passenger[]) => {
        const rideCostData = await calculateRideCost({
            startingPlaceCountry,
            destinationPlaceCountry,
            distance,
            dateStart,
            car,
            passengers: passengers ? passengers.map(passenger => passenger.id) : [],
        });

        if (!('message' in rideCostData)) {
            setRideCost(rideCostData);
        } else {
            console.error(rideCostData.message);
        }
    }

    const handleBackRideSet = () => {
        const { ride } = getValues();

        if (!ride) return;

        const { route, passengers, ...rideRest } = ride;

        setBackRide({
            route: {
                startingPlace: ride.route.destinationPlace,
                destinationPlace: ride.route.startingPlace,
                startingPlaceCountry: ride.route.destinationPlaceCountry,
                destinationPlaceCountry: ride.route.startingPlaceCountry,
                distance: ride.route.distance,
                isLocal: ride.route.isLocal,
                coordinator: ride.route.coordinator,
                placeOfService: ride.route.placeOfService,
            },
            ...rideRest
        });

        setDateStart(ride.dateStart);
        setValue('backRide.route.isLocal', !!ride?.route?.isLocal);
    };

    const setBackRidePassengers = () => {
        if (checkedBackRide) {
            const { ride } = getValues();
            if (!ride) return;

            const { passengers: ridePassengers } = ride;

            if (ridePassengers && ridePassengers.length > 0) {
                const selectedPassengersTemp: Passenger[] = [];

                ridePassengers.forEach((passenger) => {
                    if (passenger.workerId !== undefined && passenger.name !== undefined) {
                        selectedPassengersTemp.push({
                            id: passenger.workerId,
                            name: passenger.name,
                        })
                    }
                })
                setSelectedPassengers(selectedPassengersTemp);

                if (backRide) {
                    const { passengers: backRidePassengers, ...backRideRest } = backRide;

                    setBackRide({
                        passengers: ridePassengers,
                        ...backRideRest
                    })
                }
            } else {
                setSelectedPassengers([]);
            }
        } else {
            setSelectedPassengers(undefined);
        }
    }

    const handleDateChange = (dateInput: string, onChangeCallback: (...event: any[]) => void) => {
        if (moment(dateInput).isAfter(moment(dateStart).add(1, 'days'))) {
            setToast(t("travelAllowance.ride.incorrectDate"));
            setShowToast(true);
            return;
        }

        dateInput = moment(dateInput).format('YYYY-MM-DD')

        setBackRideDateStart(dateInput);
        onChangeCallback(dateInput);
    }

    return (
        <>
            <IonLoading onDidDismiss={() => setShowLoader(false)} isOpen={showLoader}/>
            <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message={toast}
                duration={6000}
                position="top"
                color="danger"
            />
            <Accordion isOpen={true} allowToggle={true} title={t('travelAllowance.ride.backRide')}>
                <IonRow>
                    <IonItem lines="none">
                        <StyledIonLabel
                            className="ion-text-wrap">{t('travelAllowance.ride.addBackRide')}</StyledIonLabel>
                        <StyledIonCheckbox
                            slot="start"
                            onIonChange={() => {
                                setCheckedBackRide(prevState => !prevState);
                            }}
                        />
                    </IonItem>
                </IonRow>
                {
                    checkedBackRide &&
                    <>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.startDate')}
                            </IonCol>
                        </IonRow>
                        <StyledInputGroup>
                            <IonCol size="10" className="label">
                                <Controller
                                    name={'backRide.dateStart'}
                                    control={control}
                                    defaultValue={moment(backRideDateStart).format('YYYY-MM-DD')}
                                    rules={{required: true}}
                                    render={({onChange}) => (
                                        <DateInput
                                            presentation="date"
                                            format="DD.MM.YYYY"
                                            onChange={value => {
                                                handleDateChange(value, onChange);
                                            }}
                                            min={moment(dateStart).format()}
                                            max={moment(dateStart).add(1, 'days').format()}
                                            cancelText={t("common.dateCancel")}
                                            doneText={t("common.dateDone")}
                                            placeholder={t("common.select")}
                                            value={moment(backRideDateStart).format('YYYY-MM-DD')}
                                        />
                                    )}
                                />
                            </IonCol>
                            <IonCol size="2" className="label">
                                <StyledInputUnit icon={true}>
                                    <IonIcon slot="icon-only" icon={CalendarIcon}/>
                                </StyledInputUnit>
                            </IonCol>
                        </StyledInputGroup>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('travelAllowance.backRide.startingPlace')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label">
                                <CityAutocomplete disabled={true}
                                                  defaultValue={backRide?.route.startingPlace}/>
                                <input ref={register}
                                       name={'backRide.route.startingPlace'}
                                       type={"hidden"}
                                       value={backRide?.route.startingPlace}/>
                            </IonCol>
                            <input ref={register}
                                   name={'backRide.route.startingPlaceCountry'}
                                   type={"hidden"}
                                   value={backRide?.route.startingPlaceCountry}
                            />
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.destinationPlace')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label">
                                <CityAutocomplete disabled={true}
                                                  defaultValue={backRide?.route.destinationPlace}/>
                                <input ref={register}
                                       name={'backRide.route.destinationPlace'}
                                       type={"hidden"}
                                       value={backRide?.route.destinationPlace}/>
                            </IonCol>
                            <input ref={register}
                                   name={'backRide.route.destinationPlaceCountry'}
                                   type={"hidden"}
                                   value={backRide?.route.destinationPlaceCountry}
                            />
                        </IonRow>
                        <input ref={register({setValueAs: v => v === 'true'})}
                               name={'backRide.route.isLocal'}
                               type={"hidden"}/>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.distance')}
                            </IonCol>
                        </IonRow>
                        <StyledInputGroup>
                            <IonCol size="10" className="label">
                                <StyledInput
                                    ref={register({valueAsNumber: true})}
                                    name={'backRide.route.distance'}
                                    defaultValue={backRide?.route.distance || undefined}
                                    readOnly={true}
                                />
                            </IonCol>
                            <IonCol size="2" className="label">
                                <StyledInputUnit>km</StyledInputUnit>
                            </IonCol>
                        </StyledInputGroup>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.coordinator')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select-full-width">
                                <StyledInput
                                    placeholder={rideFormContext?.coordinator?.name ?? ''}
                                    disabled={true}
                                ></StyledInput>
                                <input ref={register}
                                       name={'backRide.route.coordinator'}
                                       type={"hidden"}
                                       value={rideFormContext?.coordinator?.id ?? ''}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.placeOfService')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select-full-width">
                                <StyledInput
                                    placeholder={rideFormContext?.placeOfService?.name ?? ''}
                                    disabled={true}
                                >
                                </StyledInput>
                                <input ref={register}
                                       name={'backRide.route.placeOfService'}
                                       type={"hidden"}
                                       value={rideFormContext?.placeOfService?.id ?? ''}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label mt-8 required">
                                {t('travelAllowance.backRide.car')}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="select-full-width">
                                <IonSelect
                                    placeholder={t('travelAllowance.backRide.selectCar')}
                                    value={backRide?.car}
                                    disabled={true}
                                >
                                    {rideFormContext.cars.map((car: Car) => {
                                        return <IonSelectOption
                                            key={car.id}
                                            value={car.id}>{car.brand} {car.model} {car.licensePlate}</IonSelectOption>
                                    })}
                                </IonSelect>
                                <input ref={register({valueAsNumber: true})}
                                       name={'backRide.car'}
                                       type={"hidden"}
                                       value={backRide?.car}/>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('travelAllowance.backRide.rate')}
                            </IonCol>
                        </IonRow>
                        <StyledInputGroup unit={true}>
                            <IonCol size="10" className="label">
                                <StyledInput
                                    value={rideCost?.rate || '-'}
                                    disabled={true}/>
                            </IonCol>
                            <IonCol size="2" className="label">
                                <StyledInputUnit>{rideCost?.currency || '-'}/km</StyledInputUnit>
                            </IonCol>
                        </StyledInputGroup>
                        <IonRow>
                            <IonCol size="12" className="label required">
                                {t('travelAllowance.backRide.cost')}
                            </IonCol>
                        </IonRow>
                        <StyledInputGroup unit={true}>
                            <IonCol size="10" className="label">
                                <StyledInput value={rideCost?.amount || '-'}
                                             disabled={true}/>
                            </IonCol>
                            <IonCol size="2" className="label">
                                <StyledInputUnit>{rideCost?.currency || '-'}</StyledInputUnit>
                            </IonCol>
                        </StyledInputGroup>
                        <PassengersFormField prefix={'backRide'}
                                             setPassengers={setSelectedPassengers}
                                             defaultPassengers={backRide?.passengers}
                                             coordinator={rideFormContext.coordinator}
                                             placeOfService={rideFormContext.placeOfService}
                        />
                    </>
                }
            </Accordion>
        </>
    );
};

export default BackRideFormFields;
