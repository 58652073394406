import {appApi} from "./api.service";
import {getExtension} from "./file.service";
import {Contract} from "../models/contract";
import {ContractStatus} from "../enums/contractStatus";
import moment from "moment";
import {Device} from "@capacitor/device";
import {ContractExitInterviewQuestion} from "../models/contractExitInterview";


let endpointPrefix = 'worker/contracts';

const getContracts = async () => {
    return appApi.get(endpointPrefix);
}

const getContractsNoLoader = async () => {
    return appApi.get(endpointPrefix, {
        headers: {
            'No-Loader': true
        }
    });
}

const getContractsToSign = async () => {
    return await getContractsNoLoader().then(response => {
        let contracts: [] = response?.data ;

        return contracts.filter((contract: Contract) => {
            return contract.sign_status === ContractStatus.WAITING_FOR_WORKER_SIGN || contract.sign_status === ContractStatus.WAITING_FOR_WORKER_SIGN_STATEMENT || contract.sign_status === ContractStatus.WAITING_FOR_DOCUMENTS || contract.sign_status === ContractStatus.SEND_BY_KO;
        });
    });
}

const getContractTisax = async () => {
    let contacts = await getContracts().then(response => {
        let contracts: [] = response?.data ;

        return contracts.filter((contract: Contract) => {
            return contract.need_tisax_sign;
        });
    });

    return contacts[0];
}

const getForeignTermsSignContract = async () => {
    let contacts = await getContracts().then(response => {
        let contracts: [] = response?.data ;

        return contracts.filter((contract: Contract) => {
            return contract.need_foreign_terms_sign;
        });
    });

    return contacts[0];
}

const hasUnsignedContracts = async () => {
    return await getContractsToSign().then(r => r.length > 0);
}

const hasTisaxContract = async () => {
    let contract = await getContractTisax();
    return contract !== undefined;
}

const hasForeignTermsSignContract = async () => {
    let contract = await getForeignTermsSignContract();
    return contract !== undefined;
}

const getContract = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id);
}

const getContractAgreement = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/agreement/' + id);
}

const getContractPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download', {
        responseType: "blob"
    });
}

const getContractCertificatePdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-certificate', {
        responseType: "blob"
    });
}

const getContractRegulationsPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-regulations', {
        responseType: "blob"
    });
}

const getContractAgreementPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-agreement', {
        responseType: "blob"
    });
}

const getContractFileBase64 = async (id: bigint | string, fileId: bigint | number | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/contract-file/'+ fileId +'/base64-generate');
}

const saveContractComments = async (id: any, comment: string, description: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/comment', {
        'comment': comment,
        'description': description
    });
}

const signContract = async (id: any) => {
    return appApi.post(endpointPrefix + '/' + id + '/sign', {
        'rulesAccepted': 1,
        'disclaimerAccepted': 1,
        'contractFormAccepted': 1,
        'tisaxSignedByWorker': 1,
        'foreignTermsAccepted': 1,
        'formOfEmploymentInfoAccepted': 1
    });
}

const signContractVerify = async (id: any, code: string) => {
    let device = await Device.getInfo();

    return appApi.post(endpointPrefix + '/' + id + '/sign/code-verification', {
        'verificationCode': code,
        'deviceName': device.model + ' (' + device.osVersion + ')'
    });
}

const terminateContract = async (id: bigint) => {
    return appApi.post(endpointPrefix + '/' + id + '/terminate', {
        'terminateCheckbox': 1,
    });
}

const rejectContract = async (id: bigint) => {
    return appApi.post(endpointPrefix + '/' + id + '/reject');
}

const terminateContractVerify = async (id: any, code: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/terminate/code-verification', {
        'verificationCode': code
    });
}

const getContractTerminationPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-termination', {
        responseType: "blob"
    });
}

const getContractChangeTypePdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-change-type', {
        responseType: "blob"
    });
}

const getContractFolders = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/files-folders');
}

const getContractFolderFiles = async (id: bigint | number | string, folderId: bigint | number | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/files-folders/' + folderId);
}

const newContractFolder = async (id: bigint, name: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/files-folders/new', {
        name: name,
    });
}

const removeContractFolder = async (id: bigint | number | string, folderId: bigint | number | string) => {
    return appApi.post(endpointPrefix + '/' + id + '/files-folders/' + folderId + '/remove');
}

const uploadPhoto = async (id: bigint, folderId: bigint, file: Blob | File, name: string) => {
    let formData = new FormData();
    formData.append("files[]", file, name);
    return appApi.post(endpointPrefix + '/' + id + '/files-folders/' + folderId + '/files/upload', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

const removePhoto = async (id: bigint | number, folderId: bigint | number, fileId: bigint | number) => {
    return appApi.post(endpointPrefix + '/' + id + '/files-folders/' + folderId + '/files/' + fileId + '/remove');
}

const renamePhoto = async (id: bigint | number, folderId: bigint | number, fileId: bigint | number, name: string, extension: string) => {
    if (getExtension(name) !== extension) {
        name += ('.' + extension);
    }

    return appApi.post(endpointPrefix + '/' + id + '/files-folders/' + folderId + '/files/' + fileId + '/rename', {
        name: name
    });
}

const isStarted = (contract: Contract) => {
    return moment(contract.start_at).isSameOrBefore(moment(), 'd');
}

const documentsStatementGenerate = async (id: bigint, schoolName: string, city: string, schoolYear: string, schoolPeriod: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/documents-statement/generate', {
        'schoolName': schoolName,
        'city': city,
        'schoolYear': schoolYear,
        'schoolPeriod': schoolPeriod
    });
}

const documentsStatementSign = async (id: bigint) => {
    return appApi.post(endpointPrefix + '/' + id + '/documents-statement/sign');
}

const documentsStatementVerify = async (id: any, code: string) => {
    return appApi.post(endpointPrefix + '/' + id + '/documents-statement/sign/code-verification', {
        'verificationCode': code
    });
}

const documentsStatementPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/download-documents-statement', {
        responseType: "blob"
    });
}

const saveExitInterview = async (id: bigint, selectedAnswers: any, department?: string, coordinator?: string) => {
    let params = {
            form: {
                department: department,
                coordinator: coordinator,
                question: selectedAnswers
            }
        };

    return appApi.post(endpointPrefix + '/' + id + '/exit-interview', params);
}

export const exitInterviewDepartment = () => {
    return appApi.get(endpointPrefix + '/exit-interview/departments')
}

export const exitInterviewCoordinator = () => {
    return appApi.get(endpointPrefix + '/exit-interview/coordinators')
}

const getAdditionalDocumentBase64 = async (id: bigint | string, fileId: bigint | number | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/additional-document/'+ fileId +'/base64-generate');
}

const getPayrollRegulationsPdf = async (id: bigint | string) => {
    return appApi.get(endpointPrefix + '/' + id + '/payroll-regulations', {
        responseType: "blob"
    });
}

const getActiveContract = async () => {
    return appApi.get(`${endpointPrefix}/active`);
}

export {
    getContracts,
    getContractsToSign,
    hasUnsignedContracts,
    getContract,
    getContractPdf,
    getContractCertificatePdf,
    getContractRegulationsPdf,
    getContractAgreement,
    getContractAgreementPdf,
    saveContractComments,
    signContract,
    signContractVerify,
    terminateContract,
    terminateContractVerify,
    rejectContract,
    getContractFolders,
    getContractFolderFiles,
    newContractFolder,
    removeContractFolder,
    uploadPhoto,
    removePhoto,
    renamePhoto,
    getContractTerminationPdf,
    getContractChangeTypePdf,
    isStarted,
    getContractFileBase64,
    documentsStatementGenerate,
    documentsStatementSign,
    documentsStatementVerify,
    documentsStatementPdf,
    getContractTisax,
    hasTisaxContract,
    getForeignTermsSignContract,
    hasForeignTermsSignContract,
    saveExitInterview,
    getAdditionalDocumentBase64,
    getPayrollRegulationsPdf,
    getActiveContract
};
