import {IonCard, IonList} from "@ionic/react";
import styled, {css} from "styled-components";

export const StyledTravelAllowanceContent = styled.div`
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .travel-division {
    .ride-box {
      height: 53vh;
      overflow: auto;
    }
    
    .car-box,
    .ride-route-box {
      height: 56vh;
      overflow: auto;
    }

    .button-box {
      margin-top: 15px;
      width: 100%;
    }
  }
`;

export const IonCardShadowStyle = styled(IonCard)`
  width: 100%;
  --background: #fff;
  --background-dots: 255, 255, 255;
  --border-radius: 4px;
  padding: 0;
  margin: 0 0 5px -0.5%;
  font-size: 12px;

  &.error {
    border: 1px solid #F43030;
  }
  
  .wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  .title-small {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #000000;
  }

  .title-card {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #000000;
  }

  .subtitle {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .mt-4 {
    margin-left: 4px;
  }

  .wrapper-btn {
    display: flex;
    justify-content: space-evenly;
    gap: 8px;
    flex-direction: column;
  }
`

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 30px 0;
`

export const StyledButton = styled.button`
  --background: #0082f9;
  width: 100%;
  font-size: 12px;

  .button {
    color: white;
    font-size: 12px;
  }

  .btn {
    color: white;
    width: 100%;
    padding: 12px;
    background: #0082f9;
    border-radius: 6px;
    text-align: center;
  }
`;

export const StyledTravelAllowanceItem = styled(IonCard)`
  width: 100%;
  --background: #fff;
  --background-dots: 255, 255, 255;
  --border-radius: 4px;
  box-shadow: none;
  margin: 0 0 5px;
  border: 1px solid #888A9852;
  font-size: 12px;

  ion-card-header {
    padding: 11px 12px;
  }

  ion-badge {
    --background: #E7E8EA;
    --color: rgba(38, 37, 50, 0.5);
    font-size: 9px;
  }

  &.accepted {
    border: none;
    --background: #D5F0C1;
    --background-dots: 213, 240, 193;

    ion-badge {
      --background: #56BA02;
      --color: #fff;
    }
  }

  &.quarantine {
    border: none;
    --background: #FFF1D1;
    --background-dots: 255, 241, 209;

    ion-badge {
      --background: #F5AB00;
      --color: #fff;
    }
  }

  &.rejected {
    border: none;
    --background: #FFDEDE;
    --background-dots: 255, 222, 222;

    ion-badge {
      --background: #F50000;
      --color: #fff;
    }
  }

  ion-card-subtitle {
    display: flex;
    justify-content: space-between;
  }

  .travel-destinations {
    font-size: 14px;

    .place {
      flex-shrink: 0;
    }
  }

  .dots {
    flex-grow: 1;
    display: grid;
    grid-template-columns: auto auto auto;

    .dot {
      overflow: hidden;
      text-wrap: nowrap;
      margin: -2px 5px 0 5px;
      display: flex;
      align-items: center;
      position: relative;

      .gradient {
        background: linear-gradient(to left, rgba(var(--background-dots), 1), rgba(var(--background-dots), 0.5));
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }

      &.right {
        justify-content: flex-start;

        .gradient {
          background: linear-gradient(to left, rgba(var(--background-dots), 1), rgba(var(--background-dots), 0.5));
        }
      }

      &.left {
        justify-content: flex-end;

        .gradient {
          background: linear-gradient(to right, rgba(var(--background-dots), 1), rgba(var(--background-dots), 0.5));
        }
      }
    }
  }
`;

export const StyledOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 8px;
`;

export const StyledButtonOption = styled.div`
  .btn-option {
    color: #0082f9;
    width: 100%;
    padding: 12px;
    background: #cce4f4;
    border-radius: 6px;
    text-align: center;
    font-size: 12px;
  }
`;

export const StyledAccordion = styled.section`
  .row {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    text-wrap: nowrap;

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .toggle {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }
  }

  .content {
    &.open {
      display: block;
    }

    &.hidden {
      display: none;
    }
  }
  
  hr {
    width: 100%;
    border-bottom: 1px solid #888A9852;
  }

  .aaa {
    text-align: center;
    padding: 12px 0;
  }

  .aaa :not(:first-child) {
    text-align: left;
  }

  .answer-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 103%;
    margin-left: -2%;
    padding: 12px 0;
  }

  ion-item {
    --padding-start: 0;
    --inner-border-width: 0;
  }

  ion-checkbox {
    --size: 24px;
    --background-checked: #0085FF;
    margin: 0 9px 0 0;
  }

  ion-checkbox::part(container) {
    border-radius: 4px;
    border: 1px solid #888A9852;
  }

  ion-label {
    font-size: 15px;
  }

  .select {
    background-color: rgb(244, 244, 249);
    border-color: rgb(244, 244, 249);
    width: 100%;
    border-radius: 12px !important;

    input {
      all: unset;
      font-weight: 600;
      color: #000000;
      border-radius: 12px;
      border-color: rgb(244, 244, 249);
    }

    img.down {
      display: none;
    }
  }

  ion-select {
    --placeholder-color: black;
    --placeholder-opacity: 1;
    border: 1px solid rgb(244, 244, 249);
    width: 100%;
    padding: 9px;
    border-radius: 12px;
  }

  .select-icon {
    color: #949494;
  }

  ion-select::part(text) {
    color: black;
    font-size: 12px;
  }

  ion-select::part(placeholder) {
    color: black;
    font-size: 12px;
  }

  ion-select::part(icon) {
    color: #949494;
  }

  .label {
    font-size: 12px;
  }

  .chose-other {
    display: flex;
    align-items: center;
    margin: 8px 0;

    hr {
      width: 100%;
      border-bottom: 1px solid #888A98;
    }

    .one-line {
      font-size: 11px;
      white-space: nowrap;
      padding: 0 8px;
    }
  }

  .no-padding {
    --inner-padding-end: 0;
  }

  .input-style {
    all: unset;
    display: block;
    width: 108%;
  }

  .input {
    all: unset;
    border: 1px solid #888A9852;
    border-radius: 4px;
    height: 42px;
    padding: 0;
    width: 108%;
  }

  .new-input {
    background: #F4F4F9;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 0px 20px !important;
    margin-bottom: 8px;
    width: 100%;
    position: relative;
    color: #000000;
  }

  .new-input-szt {
    background: #F4F4F9;
    border-radius: 12px 0 0 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 0px 20px !important;
    width: 100%;
    position: relative;
    color: #000000;
    border-radius: 12px 0 0 12px;
    height: 45px;
  }

  .textarea-style {
    all: unset;
    display: flex;
    flex-direction: row;
    display: block;
    width: 108%;
  }

  .textarea {
    all: unset;
    border: 1px solid #888A9852;
    border-radius: 4px;
    padding: 0;
    width: 108%;
  }

  .gap-style {
    margin: 15px 0;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .color-0085FF {
    color: #0085FF;
  }

  .second-accordion {
    width: 100%;
    margin: 8px 0;

    .two-input-style {
      width: 100%;
      display: flex;
      flex-direction: column;
      --inner-padding-end: 0;

      .input {
        border-width: 1px;
        border-color: #888A9852;
        border-style: solid;
        border-radius: 4px 0 0 4px;
        flex-grow: 1;
      }

      .szt-input {
        background-color: #888A9852;
        color: #262532;
        font-size: 12px;
        border-radius: 0 4px 4px 0;
        padding: 15px;
        flex-shrink: 0;
      }
    }
  }

  .arrow {
    background-color: #D7EAFB;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50%;
    }
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .range-values {
    display: flex;
    justify-content: space-between;
  }

  .range-frame {
    border: 1px solid #888A9852;
    margin-top: 8px;
    padding: 10px;
    border-radius: 4px;
  }

  .textarea-width {
    width: 100%;
  }

  .worker-row {
    .select {
      border: 1px solid #F4F4F9;
      background: #F4F4F9;
      border-radius: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      padding: 14px 20px;
      margin-bottom: 8px;
      width: 100%;
      position: relative;
      color: #000000;
    }

    .trash {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      img {
        width: 14px;
      }
    }
  }

  .select-full-width {
    border: 1px solid #F4F4F9;
    background: #F4F4F9;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 3px 0px;
    margin-bottom: 8px;
    width: 100%;
    position: relative;
    color: #000000;
    font-weight: 600;
  }

  no-padding {
    --inner-padding-end: 0 !important;
  }

  .hasErrors {
    border: 1px solid #F43030 !important;
  }

  .green {
    color: #00C917;
  }

  .red {
    color: #FE0200;
  }

  .padding-0-10 {
    padding: 0 10px;
  }
`

export const StyledWarningInfo = styled.div`
  background-color: white;
  color: #f8ac59;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;

  .changes-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .changes-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
`

export const StyledDangerInfo = styled.div`
  background-color: white;
  color: #F43030;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 50px;

  .rejection-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

export const RideReportRejectionReasonsHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const RideReportRejectionReasonsList = styled(IonList)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 25px 0;
`;

type RideReportRejectionReasonsItemProps = {
    selected?: boolean;
}

export const RideReportRejectionReasonsItem = styled.div<RideReportRejectionReasonsItemProps>`
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 20px;
  font-size: 14px;

  ${props => props.selected && css`
    background: #cce4f4;
    border-color: #0082f9;
  `}
`;